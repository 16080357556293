/* Header.css */
.header {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  
  .nav-container {
    background-color: #0f0f0f; /* Purple-blue color from your design */
    margin: 0 auto;
    padding: 15px 20px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    position: relative;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255);
    text-decoration: none;
    position: relative;
    padding: 5px 15px;
    font-family: 'Outfit', serif;
    font-optical-sizing: auto;
  }
  
  .nav-item.active {
    color:rgba(255, 255, 255);
  }
  .hanging-ball-div {
    position: absolute;
    top: -40px;
    width: 26px;
    height: 26px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 10px; 
    border-color: #ffffff; 
    rotate: 45deg;
    border-radius: 100% 0;
  }
  
  .hanging-ball {

    width: 15px;
    height: 15px;
    background-color: #000000;
    border-radius: 50%;
  }
  
  .icon {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .label {
    font-size: 12px;
  }
  
  /* For mobile devices */
  @media (max-width: 600px) {
    .nav-container {
      width: 90%;
      margin: 0 auto 20px;
    }
  }