/* Home.css */
.home-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .hero-section {
    background-image: url('https://beauty-double-frontend.s3.us-east-1.amazonaws.com/hpmepage.jpeg');
    background-size: cover;
    background-position: center;
    min-height: 90vh;
    display: flex;
    padding: 20px;
    align-items: center;
  }
  
  .home-logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .company-logo {
    width: 200px;
    height: auto;
    transition: all 0.3s ease;
  }
  
  /* Logo shake animation */
  .company-logo:hover {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .hero-content {
    flex: 1;
    max-width: 60%;
    background-color: rgba(209, 179,152, 0.45);
    border-radius: 16px;
    padding: 30px;
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .intro-text p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #555;
  }
  
  .cta-container {
    display: flex;
    gap: 15px;
    margin-top: 25px;
  }
  
  .cta-button {
    background-color: #ba9e85;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(189, 157, 129, 0.5);
  }
  
  .secondary-button {
    background-color: transparent;
    color: #000000;
    border: 2px solid #ba9e85;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .secondary-button:hover {
    background-color: rgba(189, 157, 129, 0.5);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
    }
    
    .home-logo-container {
      margin-bottom: 20px;
    }
    
    .hero-content {
      padding: 20px;
    }
    
    h1 {
      font-size: 2rem;
    }
    
    .cta-container {
      flex-direction: column;
    }
  }