/* At the top of your App.css or a separate fonts.css file */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Playfair+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300'); 
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'); 

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* background-color: #c5d7f7; */
    /* background-color: #c6dbff; */
  }
  

  
  .content {
    flex: 1;
    /* padding: 20px 0px 20px 0px; */
    /* Reduced top padding since logo is now in normal flow */
    height: auto;

  }
  
  body {
    font-family: 'Montserrat', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>;
    font-style: normal; */
  }
  p {
    font-family: 'Outfit', serif;
    font-optical-sizing: auto;
  }